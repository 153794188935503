import React, { useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import "./Carousel.css"
import Slide1 from '../assets/images/home/carousel/carouselSlide3.png';
import Slide2 from '../assets/images/home/carousel/carouselSlide1.webp';
import Slide3 from '../assets/images/home/carousel/carouselSlide2.webp';
import carouselB1 from '../assets/images/home/carousel/carouselB3.png';
import carouselB2 from '../assets/images/home/carousel/carouselB1.webp';
import carouselB3 from '../assets/images/home/carousel/carouselB2.webp';


export default function CarouselComponent() {
    const carouselB = [carouselB1, carouselB2, carouselB3];
    
    const colors = [
        'rgb(229,98,78)', 'rgb(253,190,53)', 'rgb(41,170,174)'
    ]
    return (
        <div className="carousel-wrapper">
            <Carousel 
                useKeyboardArrows
                autoPlay 
                infiniteLoop
                showThumbs={false}
                renderIndicator={(onClickHandler, isSelected, index, label, width) => {

                    const spanStyle={ display: "inline-block",
                    height: '100%' };

                    const defStyle = {width: '100%', 
                                    minWidth:'50px', 
                                    maxWidth: '100px', 
                                    cursor: "pointer", 
                                    border: '1.5px solid black', 
                                    borderRadius: 5,
                                    };

                    const style = isSelected
                    ? { ...defStyle, width: '100%', minWidth:'100px', maxWidth: '150px', border: `3px solid ${colors[index]}`}
                    : { ...defStyle };
                    return (
                        <span
                        className="carousel-buttons"
                        onClick={onClickHandler}
                        onKeyDown={onClickHandler}
                        value={index}
                        key={index}
                        role="button"
                        tabIndex={0}
                        style={spanStyle}
                        >
                        
                        <img 
                        className="button-inner-img"
                        style={style} 
                        alt='1' 
                        src={carouselB[index]} 
                        />
                    </span>
                                           
                    );
                }}  
            >
                <div>
                    <img src={Slide1} />
                </div>
                <div>
                    <img src={Slide2} />
                </div>
                <div>
                    <img src={Slide3} />
                </div>
            </Carousel>
        </div>
    );
}
