import React, { useState, useEffect } from 'react';
import { Row, Col, Image, Container } from "react-bootstrap";
import { Multiselect } from 'multiselect-react-dropdown';

import './Companies.css';

export default function CFCompanies() {
    
    //for the multiselect
    const filterYears = [
        { name: '1st Year',     id: 0},
        { name: '2nd Year',     id: 1},
        { name: '3rd Year',     id: 2},
        { name: 'PEY',          id: 3},
        { name: '4th Year',     id: 4},
        { name: 'New Grad',     id: 5},
        { name: "Master's Degree",      id: 6},
        { name: 'Doctorate Degree',          id: 7},
      ]
    
      const filterDisciplines = [
        { key: "Aerospace Engineering",             name: "AERO",                             id: 0},
        { key: "Architecture",                      name: "Architecture",                     id: 1},
        { key: "Biomedical Engineering",            name: "BIOMED",                           id: 2},
        { key: "Chemical Engineering",               name: "CHEM",                             id: 3},
        { key: "Civil Engineering",                 name: "CIVIL",                            id: 4},
        { key: "Commerce & Business",               name: "Commerce & Business",              id: 5},
        { key: "Computer Science",                  name: "Computer Science",                 id: 6},
        { key: [
            "Electrical Engineering",
            "Computer Engineering"
        ],                                          name: "ECE",                              id: 7},
        { key: "Engineering Science",               name: "ENGSCI",                           id: 8},
        { key: "Industrial Engineering",            name: "INDY",                             id: 9},
        { key: "Life Sciences",                     name: "LIFESCI",                          id: 10},
        { key: "Material Engineering",              name: "MSE",                              id: 11},
        { key: "Mechanical Engineering",            name: "MECH",                             id: 12},
        { key: "Mineral Engineering",               name: "MIN",                              id: 13},
        { key: "Physical/Mathematical Sciences",    name: "Physical/Mathematical Sciences",   id: 14},
        { key: "Social Sciences",                   name: "Social Sciences",                  id: 15},
      ]

      const [yearOptions] = useState(filterYears);
      const [disciplineOptions] = useState(filterDisciplines);

      const [yearFilter, setYearFilter] = useState([]);
      const [disciplineFilter, setDisciplineFilter] = useState([]);
      const [textFilter, setTextFilter] = useState("");


    const [companies, setCompanies] = useState([]);

    const textFilterOnChange = (event) => {
        setTextFilter(event.target?.value);
      };

    useEffect(() => {
        fetch("/api/cf_companies")
            .then(response => response.json())
            .then(data => {
                console.log(data['cf_companies']);
                setCompanies(data['cf_companies'].map(res =>
                ({
                    name: res.name,
                    description: res.description,
                    logo: res.logo,
                    targetYears: res.target_years,
                    targetPrograms: res.target_programs,
                    link: res.link,
		            tier: res.tier,
                })
                ).sort(function(c1, c2) {
                    return c1.name.localeCompare(c2.name);
                   })
                );
            });
    }, []);

    const filteredCompanies = companies
        .filter(company => company.name && company.name.toLowerCase().includes(textFilter.toLowerCase())
	    && (company.tier !== "Event")
            && (yearFilter.length === 0 || yearFilter.some(year => company.targetYears.includes(year)))
            && (disciplineFilter.length === 0 || disciplineFilter.some(discipline => company.targetPrograms.includes(discipline)))
        );
    
      const handleYearFilterChange = (selectedList) => {
            const tempFilter = [];
            for (let i = 0; i < selectedList.length; i++) {
                tempFilter.push(selectedList[i].name)	
            }
            setYearFilter(tempFilter)
      };
    
      const handleDisciplineFilterChange = (selectedList) => {        
        setDisciplineFilter(selectedList.reduce((selections, curr_selection) => {
            Array.isArray(curr_selection.key) ? selections.push(...curr_selection.key) : selections.push(curr_selection.key)
            return selections
        }, []))
      };


    return (
        <>
        <div>
            <div className="header-bg">
                <Container className="companies-header-container">
                    <h1>Career Fair Companies</h1>
                    {/* <h4>More companies to be announced soon!</h4> */}
                    <Row>
                        <Col md={6} className='company-search-bar-container'>
                            <input placeholder="Search company name or keyword..." value={textFilter} onChange={textFilterOnChange} />
                        </Col>
                        
                        <Col className='years-filter' xs={12} sm={6} md={3}>
                            <Multiselect
                                options={yearOptions}
                                placeholder="Select Year(s)"
                                onSelect={handleYearFilterChange}
							    onRemove={handleYearFilterChange}
                                displayValue="name"
                                avoidHighlightFirstOption="true"
                            />
                        </Col>

                        <Col className="disciplines-filter" xs={12} sm={6} md={3}>
                            <Multiselect
                                options={disciplineOptions}
                                placeholder="Select Discipline(s)"
							    onSelect={handleDisciplineFilterChange}
							    onRemove={handleDisciplineFilterChange}
                                displayValue="name"
                                avoidHighlightFirstOption="true"
                                className='displines-filter-multiselect-component'
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container className="company-list-box">
                <h5>{filteredCompanies.length} companies found.</h5>
                {filteredCompanies.map(company => <CompanyRow company={company} key={company.name} />)}
            </Container>
         </div>
        </>
    );
}

const CompanyRow = ({company}) => {
    const [expanded, setExpanded] = useState(false);

    console.log(company.logo, company.name)
    return (
        <div className="company-row">
            {company.isPlaceholder ?
                <Row>
                    <Col>
                        <Row xs={12} sm={9} md={10}>
                            <a>{company.name}</a>
                        </Row>
                    </Col>
                </Row>
                :
                <Row>
                    <Col xs={12} sm={3} md={2}>
                        {company.description && <Image className="company-row-logo" src={company.logo} fluid />}
                    </Col>
                    <Col>
                        <Row xs={12} sm={9} md={10}>
                            {company.link ? (
                                <a href={company.link}>{company.name}</a>
                            ) : (
                                <a>{company.name}</a>
                            )}
                        </Row>
                        {(company.targetPrograms.length + company.targetYears.length > 0) && (
                            <Row>
                                <Col className="target-info">
                                    <b>HIRING: </b>
                                    <span className="highlight">{company.targetPrograms.join(", ")}</span>
                                </Col>
                                <Col className="target-info">
                                    <b>YEAR: </b>
                                    <span className="highlight-gray">{company.targetYears.join(", ")}</span>
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <p className={expanded ? "" : "minimized-text"}>{company.description || "More information coming soon!"}</p>
                            {company.description && !expanded && <p className="expand-button" onClick={() => {setExpanded(true)}}>Read More</p>}
                        </Row>
                    </Col>
                </Row>
            }
            
        </div>
    );
  }
