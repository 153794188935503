import React from "react";
import LogoGrid from "../components/LogoGrid";
import Col from "react-bootstrap/Col";
import "./Partners.css";

export default class SilverPartners extends React.Component {
  render() {
    return (
      <Col xs={12}>
          <LogoGrid 
              logos={this.props.partners} 
              tier='Silver' 
              handleShowPopup={this.props.handleShowPopup}
          />
      </Col>
    );
    }
}
